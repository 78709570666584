@import './animate.css';
@import './swiper.css';
@import './entry-content.css';
@import './my-account.css';
/* Becareful! import only things that can use in all projects and won't have side effects to other ongoing projects! */

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply tw-antialiased tw-overflow-x-hidden;
}

.db {
  border: 1px solid salmon !important;
}

/* Hide recaptcha */
.grecaptcha-badge {
  display: none;
}

i {
  min-width: 0.75rem;
  line-height: 1;
}

.default-chevron-down {
  @apply tw-text-bgh-blue-light  ltr:tw-mr-3 rtl:tw-ml-3;
}

.autocomplete-chevron-down {
  @apply !tw-text-bgh-blue-light tw-text-xs tw-opacity-20 !tw-p-1.5;
}

/* COOKIE CONSENT */
.cwc-sdk-container div,
.cwc-sdk-container span,
.cwc-sdk-container a,
.cwc-sdk-container button {
  font-family: var(--font-aktiv), var(--font-satoshi), sans-serif !important;
}

.cwc-banner-desc {
  @apply !tw-text-sm !tw-text-bgh-gray-primary md:tw-max-w-2xl;
}

.cwc-setting-button,
.cwc-banner-policy-link {
  @apply !tw-text-primary !tw-decoration-bgh-blue-light;
}

.cwc-css-gr7b5n-cwc {
  opacity: 0 !important;
  pointer-events: none !important;
  transform: scale(0) !important;
  height: 0 !important;
  position: absolute !important;
}

.cwc-accept-button {
  @apply !tw-bg-primary;
}

.cwc-cookie-banner-ui-sdk .cwc-css-1lz0phs-cwc {
  background-color: rgba(255, 255, 255, 0.5) !important;
  backdrop-filter: blur(20px) !important;
  @apply !tw-rounded-lg !tw-border-transparent !tw-pb-5 md:!tw-pb-1;
}
.cwc-cookie-banner-ui-sdk .cwc-css-1p307e3-cwc {
  width: 100%;
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 4rem !important;
}

@media (min-width: 1400px) {
  .cwc-cookie-banner-ui-sdk .cwc-css-1p307e3-cwc {
    bottom: 1rem !important;
  }
}

.cwc-banner-container {
  @apply !tw-rounded-lg !tw-border-transparent !tw-shadow-main-dark-2;
}
/* COOKIE CONSENT */
